import React, { useState, useContext } from "react";
import classNames from "classnames";

import { useDispatchWithLocale, useTranslation } from "hooks";
import { MediaContext } from "contexts/MediaContext";
import env from "config/env";
import { viewPackageIncludedEvt } from "store/bookings";
import { CancellationDate } from "BookingFlow/components";
import MappingLayerContext from "../mappingLayerContext";
import WhatIsIncludedModal from "../components/WhatIsIncludedModal";

const { IMAGE_BASE_URL } = env;

function Package({
  id,
  bookDirectPhone,
  bookableOnline,
  conditions,
  conditionsTitle,
  disclaimer,
  extraInclusions,
  feesDisclaimer,
  image,
  inclusions,
  inclusionsTitle,
  longDescription,
  price,
  priceLabel,
  offerTermsAndConditionsContent,
  onSelectPackage,
  onClickCheckout,
  shortDescription,
  title,
  whatsIncludedTitleDictKey,
  disabled,
}) {
  const media = useContext(MediaContext);
  const { promoCode } = useContext(MappingLayerContext);
  const { t, formatPrice } = useTranslation();
  const dispatchWithLocale = useDispatchWithLocale();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onSelectPackageClick = () => {
    onSelectPackage(id);
    onClickCheckout(false);
  };

  const closeModal = () => setIsModalOpen(false);
  const onSelectPackageModal = () => {
    onSelectPackage(id);
    onClickCheckout(false);

    closeModal();
  };

  const showWhatIsIncludedForMobileView = !promoCode && media.isLessThan.sm;

  return (
    <>
      <div
        className={classNames("package", {
          "package--disabled": disabled,
        })}
      >
        <div className="package-image">
          {image && (
            <img
              loading="lazy"
              src={`${IMAGE_BASE_URL + image.path}`}
              alt={title}
            />
          )}
        </div>

        <div className="package-info-container">
          <div className="package-info">
            <div className="package-description">
              <h2 className="heading">{title}</h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: shortDescription }}
              />

              {!promoCode && media.isGreaterThan.sm && (
                <button
                  type="button"
                  className="btn-link view-more-information"
                  onClick={() => {
                    setIsModalOpen(true);
                    dispatchWithLocale(
                      viewPackageIncludedEvt({ packageName: title })
                    );
                  }}
                  aria-label={`${t(
                    whatsIncludedTitleDictKey.key,
                    whatsIncludedTitleDictKey.params
                  )} ${title}`}
                  aria-haspopup="dialog"
                >
                  <span>
                    {t(
                      whatsIncludedTitleDictKey.key,
                      whatsIncludedTitleDictKey.params
                    )}
                  </span>
                </button>
              )}

              <div className="offer-details" style={{ display: "none" }}>
                <dl className="inclusions">
                  {inclusions.map(({ items }) => (
                    <dd
                      key={items}
                      dangerouslySetInnerHTML={{ __html: items }}
                    />
                  ))}
                </dl>
              </div>

              <div
                style={{ display: "none" }}
                dangerouslySetInnerHTML={{ __html: longDescription }}
              />
            </div>

            <div className="package-price">
              {showWhatIsIncludedForMobileView && (
                <button
                  type="button"
                  className="btn-link view-more-information"
                  onClick={() => {
                    setIsModalOpen(true);
                    // sendAnalytics('room_view_included', room, $index,bdOffer)
                  }}
                  aria-label={`${t(
                    whatsIncludedTitleDictKey.key,
                    whatsIncludedTitleDictKey.params
                  )} ${title}`}
                  aria-haspopup="dialog"
                >
                  <span>
                    {t(
                      whatsIncludedTitleDictKey.key,
                      whatsIncludedTitleDictKey.params
                    )}
                  </span>
                </button>
              )}

              <div
                className={classNames("package-price-text", {
                  "package-price-text__no-whatIsIncluded":
                    !showWhatIsIncludedForMobileView,
                })}
              >
                <div className="nightly-rate">
                  <span>
                    {formatPrice({
                      currency: price.currency,
                      value: price.amount.withoutDecimal,
                      decimalPlaces: 0,
                    })}
                  </span>

                  <span className="night">{t(priceLabel)}</span>

                  {feesDisclaimer && (
                    <div className="resort-fee">
                      {t(feesDisclaimer.key, feesDisclaimer.params)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="package__select-package">
            {offerTermsAndConditionsContent?.cancellationBy && (
              <CancellationDate
                className="package__select-package__cancellation"
                cancellationBy={offerTermsAndConditionsContent.cancellationBy}
              />
            )}

            <button
              type="button"
              className="btn btn--primary package__select-package__button"
              onClick={onSelectPackageClick}
              aria-label={`${t("Select Package")} (${title})`}
            >
              {t("Select Package")}
            </button>
          </div>
        </div>

        {isModalOpen && (
          <WhatIsIncludedModal
            bookableOnline={bookableOnline}
            bookDirectPhone={bookDirectPhone}
            cancellationPolicy={
              offerTermsAndConditionsContent?.cancellationPolicy
            }
            closeModal={closeModal}
            feesDisclaimer={feesDisclaimer}
            conditions={conditions}
            conditionsTitle={conditionsTitle}
            inclusions={inclusions}
            inclusionsTitle={inclusionsTitle}
            longDescription={longDescription}
            onSelectPackage={onSelectPackageModal}
            price={price}
            priceLabel={priceLabel}
            propertyDisclaimer={disclaimer}
            title={title}
            whatsIncludedTitleDictKey={whatsIncludedTitleDictKey}
            extraInclusions={extraInclusions}
          />
        )}
      </div>
    </>
  );
}

export default Package;
