import get from "lodash/get";
import {
  add,
  sub,
  isAfter,
  isBefore,
  parse,
  differenceInDays,
  isSameYear,
} from "date-fns";

import { EMPLOYEE_SEARCH_ERRORS } from "store/searchResults/checkEmployeeSearchParams";
import { getEmployeeServiceDateThisYear } from "utils/datesHelpers";
import { getEmployeeRateByCode } from "Profile/utils/getEmployeeRate";

function entitlementError(nights) {
  if (nights === 1) {
    return {
      errorCode:
        EMPLOYEE_SEARCH_ERRORS.BOOKING_EXCEEDS_YEARLY_ENTITLEMENT_SINGULAR,
      params: [nights],
    };
  }
  return {
    errorCode: EMPLOYEE_SEARCH_ERRORS.BOOKING_EXCEEDS_YEARLY_ENTITLEMENT_PLURAL,
    params: [nights],
  };
}

function checkEmployeeRoomNights({
  searchParams = {},
  stayHistory = [],
  globalSettings = {},
  employeeProfile = {},
} = {}) {
  if (
    !get(searchParams, ["dates", "checkIn"]) ||
    !get(searchParams, ["dates", "checkOut"])
  ) {
    return false;
  }

  const employeeRate = getEmployeeRateByCode(searchParams.promoCode);
  if (
    !employeeRate ||
    ((!Array.isArray(stayHistory) || !stayHistory.length) &&
      !employeeRate.compRate)
  ) {
    return false;
  }
  let serviceDateThisYear;
  let serviceDateLastYear;
  let yearlyEntitlement = 0;
  if (employeeRate.compRate) {
    if (!Number.isNaN(employeeProfile.yearlyEntitlement)) {
      yearlyEntitlement = parseInt(employeeProfile.yearlyEntitlement, 10);
    }
    if (!yearlyEntitlement || Number.isNaN(yearlyEntitlement)) {
      return entitlementError(0);
    }
    serviceDateThisYear = getEmployeeServiceDateThisYear(employeeProfile);
    if (!serviceDateThisYear) {
      return entitlementError(0);
    }
    serviceDateLastYear = sub(serviceDateThisYear, { years: 1 });
  }

  const maxRoomNights30Days = globalSettings.maxRoomNights30Days || 0;
  const maxRoomNightsCalendarYear =
    globalSettings.maxRoomNightsCalendarYear || 0;
  if (
    !maxRoomNights30Days &&
    !maxRoomNightsCalendarYear &&
    !employeeRate.compRate
  ) {
    return false;
  }

  const checkInDate = parse(
    searchParams.dates.checkIn,
    "yyyy-MM-dd",
    new Date()
  );
  const checkOutDate = parse(
    searchParams.dates.checkOut,
    "yyyy-MM-dd",
    new Date()
  );

  const roomNightsThisStay = differenceInDays(checkOutDate, checkInDate);
  if (roomNightsThisStay < 1) {
    return false;
  }

  const checkOut30Before = add(checkOutDate, { days: -31 });
  const checkIn30After = add(checkInDate, { days: 30 });

  if (
    maxRoomNightsCalendarYear &&
    roomNightsThisStay > maxRoomNightsCalendarYear
  ) {
    return {
      errorCode: EMPLOYEE_SEARCH_ERRORS.MAX_ROOM_NIGHTS_YEAR,
      params: [maxRoomNightsCalendarYear, 365],
    };
  }
  if (maxRoomNights30Days && roomNightsThisStay > maxRoomNights30Days) {
    return {
      errorCode: EMPLOYEE_SEARCH_ERRORS.MAX_ROOM_NIGHTS_30,
      params: [maxRoomNights30Days],
    };
  }

  let compNightsThisServiceYear = 0;
  let compNightsLastServiceYear = 0;
  if (employeeRate.compRate) {
    let dayOfStay = checkInDate;
    while (isBefore(dayOfStay, checkOutDate)) {
      if (isBefore(dayOfStay, serviceDateThisYear)) {
        if (!isBefore(dayOfStay, serviceDateLastYear)) {
          compNightsLastServiceYear += 1;
        }
      } else {
        compNightsThisServiceYear += 1;
      }
      dayOfStay = add(dayOfStay, { days: 1 });
    }
  }

  let roomNightsYear = roomNightsThisStay;
  let compHistoryThisServiceYear = 0;
  let compHistoryLastServiceYear = 0;
  const roomNights30Dates = [];

  if (Array.isArray(stayHistory)) {
    stayHistory.forEach((booking) => {
      const bookingRateCode = get(
        booking,
        ["hotelProducts", 0, "rooms", "room", 0, "ratePlanCode"],
        ""
      );
      const stayEmployeeRate = getEmployeeRateByCode(bookingRateCode);
      if (
        !!booking.startDate &&
        !!booking.endDate &&
        !get(booking, ["hotelProducts", 0, "fsCachedExistingReservation"]) &&
        !!stayEmployeeRate
      ) {
        let stayDate = parse(booking.startDate, "yyyy-MM-dd", new Date());
        const stayEndDate = parse(booking.endDate, "yyyy-MM-dd", new Date());
        while (isBefore(stayDate, stayEndDate)) {
          if (isSameYear(checkInDate, stayDate)) {
            roomNightsYear += 1;
          }
          if (
            isAfter(stayDate, checkOut30Before) &&
            isBefore(stayDate, checkIn30After)
          ) {
            roomNights30Dates.push(stayDate);
          }
          if (stayEmployeeRate.compRate) {
            if (isBefore(stayDate, serviceDateThisYear)) {
              if (!isBefore(stayDate, serviceDateLastYear)) {
                compHistoryLastServiceYear += 1;
              }
            } else {
              compHistoryThisServiceYear += 1;
            }
          }
          stayDate = add(stayDate, { days: 1 });
        }
      }
    });
  }

  if (maxRoomNightsCalendarYear && roomNightsYear > maxRoomNightsCalendarYear) {
    return {
      errorCode: EMPLOYEE_SEARCH_ERRORS.MAX_ROOM_NIGHTS_YEAR,
      params: [maxRoomNightsCalendarYear, 365],
    };
  }
  if (
    maxRoomNights30Days &&
    roomNightsThisStay + roomNights30Dates.length > maxRoomNights30Days
  ) {
    let stayRange30Date = checkOut30Before;
    let exceededmaxRoomNights30Days = false;
    while (
      isBefore(stayRange30Date, checkInDate) &&
      !exceededmaxRoomNights30Days
    ) {
      const stayRange30Start = stayRange30Date;
      const stayRange30End = add(stayRange30Date, { days: 31 });
      const roomNights = roomNights30Dates.reduce((nightCounter, stayDate) => {
        if (
          isAfter(stayDate, stayRange30Start) &&
          isBefore(stayDate, stayRange30End)
        ) {
          return nightCounter + 1;
        }
        return nightCounter;
      }, 0);

      if (roomNightsThisStay + roomNights > maxRoomNights30Days) {
        exceededmaxRoomNights30Days = true;
      }
      stayRange30Date = add(stayRange30Date, { days: 1 });
    }

    if (exceededmaxRoomNights30Days) {
      return {
        errorCode: EMPLOYEE_SEARCH_ERRORS.MAX_ROOM_NIGHTS_30,
        params: [maxRoomNights30Days],
      };
    }
  }

  if (
    compNightsThisServiceYear &&
    compNightsThisServiceYear + compHistoryThisServiceYear > yearlyEntitlement
  ) {
    return entitlementError(yearlyEntitlement - compHistoryThisServiceYear);
  }
  if (
    compNightsLastServiceYear &&
    compNightsLastServiceYear + compHistoryLastServiceYear > yearlyEntitlement
  ) {
    return entitlementError(yearlyEntitlement - compHistoryLastServiceYear);
  }

  return false;
}

export default checkEmployeeRoomNights;
