import {
  differenceInCalendarDays,
  differenceInDays,
  isAfter,
  parse,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

import env from "config/env";

const { EMPLOYEE_RATES } = env;

export const EMPLOYEE_SEARCH_ERRORS = {
  NO_TIMEZONE:
    "We are sorry but we are experiencing some technical difficulty.  Please try again later.",
  NO_AVAILABLE_ROOMS:
    "We are sorry, there are no longer available rooms on the dates you have requested, as they have sold out since the last calendar update.  Please do not reach out directly to the hotel to inquire about availability.",
  BOOKING_SAME_DATES:
    "You have an existing booking at another property for the dates you have selected.  To proceed, please cancel your existing booking or select new dates.",
  BOOKINGS_SAME_HOTEL:
    "The maximum number of rooms you can book for a single stay is 2 rooms.",
  HOTEL_EXCEPTION:
    "You have selected a hotel where there are currently program restrictions in place. Please contact your local People & Culture team for further questions.",
  ELIGIBILITY:
    "Your eligibility for booking special employee rates could not be confirmed. Please contact your People & Culture team for assistance.",
  TECHNICAL:
    "We are sorry but we are experiencing some technical difficulty. Please try again later.",
  MAX_ROOM_NIGHTS_30:
    "You have booked more than {0} total room nights in a 30-day period. To proceed, please cancel any existing reservations or select new dates.",
  MAX_ROOM_NIGHTS_YEAR:
    "You have booked more than {0} total room nights over a calendar year ({1} days). To proceed, please cancel any existing reservations. Please refer to the Employee Travel Program policy to review your annual room night entitlement.",
  MAX_CONSECUTIVE_ROOM_NIGHTS_SAME_HOTEL:
    "You have booked more than {0} room nights in the same hotel. To proceed, please cancel any existing reservations or select new dates.",
  MAX_STAYS_PER_YEAR_SAME_HOTEL:
    "You have booked more than {0} stays in the same hotel over a calendar year. To proceed, please cancel any existing reservations or select another hotel.",
  BOOKING_EXCEEDS_POLICY_LIMITATIONS:
    "This booking exceeds one or more policy limitations, please revise the booking to align with the policy and resubmit.",
  BOOKING_EXCEEDS_YEARLY_ENTITLEMENT_SINGULAR:
    "Booking exceeds your remaining discovery nights entitlement of {0} night for the current service year. Please adjust your stay and try again.",
  BOOKING_EXCEEDS_YEARLY_ENTITLEMENT_PLURAL:
    "Booking exceeds your remaining discovery nights entitlement of {0} nights for the current service year. Please adjust your stay and try again.",
};

export default function checkEmployeeSearchParams({
  searchParams = {},
  property = {},
  employeeProfile = {},
  workplaces = [],
}) {
  // Hotel Exception
  if (searchParams.hotelCode && employeeProfile.workPlace) {
    const employeeWorkplace = workplaces.find(
      (workplace) =>
        !!workplace.location &&
        workplace.location.toUpperCase() ===
          employeeProfile.workPlace.toUpperCase()
    );

    if (
      employeeWorkplace &&
      employeeWorkplace.owsExceptions &&
      employeeWorkplace.owsExceptions.includes(searchParams.hotelCode)
    ) {
      return EMPLOYEE_SEARCH_ERRORS.HOTEL_EXCEPTION;
    }
  }

  // Max 1 Room
  if (Array.isArray(searchParams.rooms) && searchParams.rooms.length > 1) {
    return EMPLOYEE_SEARCH_ERRORS.NO_AVAILABLE_ROOMS;
  }

  if (!searchParams.dates?.checkIn || !searchParams.dates?.checkOut) {
    return false;
  }

  // Max Nights
  const maxNights = EMPLOYEE_RATES.rateDetails.reduce(
    (empMaxNight, rateCodeDetails) => {
      if (
        rateCodeDetails.rateCodes.includes(searchParams.promoCode) &&
        rateCodeDetails.maxNights &&
        rateCodeDetails.maxNights > empMaxNight
      ) {
        return rateCodeDetails.maxNights;
      }
      return empMaxNight;
    },
    0
  );

  if (
    differenceInCalendarDays(
      parse(searchParams.dates.checkOut, "yyyy-MM-dd", new Date()),
      parse(searchParams.dates.checkIn, "yyyy-MM-dd", new Date())
    ) > maxNights
  ) {
    return EMPLOYEE_SEARCH_ERRORS.NO_AVAILABLE_ROOMS;
  }

  // Max Advance Check In
  if (
    property.timeZone &&
    differenceInDays(
      zonedTimeToUtc(
        parse(searchParams.dates.checkIn, "yyyy-MM-dd", new Date()),
        property.timeZone
      ),
      new Date()
    ) >=
      EMPLOYEE_RATES.maxDaysCheckIn - 1
  ) {
    return EMPLOYEE_SEARCH_ERRORS.NO_AVAILABLE_ROOMS;
  }

  if (
    employeeProfile.stayStart &&
    isAfter(
      parse(employeeProfile.stayStart, "yyyy-MM-dd", new Date()),
      parse(searchParams.dates.checkIn, "yyyy-MM-dd", new Date())
    )
  ) {
    return EMPLOYEE_SEARCH_ERRORS.ELIGIBILITY;
  }

  return false;
}
