import React, { useState } from "react";
import { useSelector } from "react-redux";

import { selectBookingSupplierError } from "store/bookings";
import { ErrorMessage } from "BookingFlow/components";
import { getErrorCodeForTranslations } from "utils/errorCodes";
import Package from "./Package";

function AvailablePackages({
  offers,
  bookableOnline,
  onSelectPackage,
  employeeMode = false,
}) {
  const error = useSelector(selectBookingSupplierError);
  const [selectedPackage, setSelectedPackage] = useState(null);

  return (
    <>
      {error && (
        <ErrorMessage messageDictKey={getErrorCodeForTranslations(error)} />
      )}

      <ul className="available-packages">
        {offers.map((offer) => (
          <li key={offer.resultId} className="available-package">
            <Package
              id={offer.resultId}
              bookDirectPhone={offer.bookDirectPhone}
              bookableOnline={bookableOnline}
              conditions={offer.conditions}
              conditionsTitle={offer.conditionsTitle}
              disclaimer={offer.disclaimer}
              extraInclusions={offer.extraInclusions}
              feesDisclaimer={offer.feesDisclaimer}
              image={offer.image}
              inclusions={offer.inclusions}
              inclusionsTitle={offer.inclusionsTitle}
              longDescription={offer.longDescription}
              price={offer.price}
              priceLabel={offer.priceLabel}
              offerTermsAndConditionsContent={
                !employeeMode && offer.offerTermsAndConditionsContent
              }
              onSelectPackage={(id) => setSelectedPackage(id)}
              onClickCheckout={(asMember) => onSelectPackage(offer, asMember)}
              shortDescription={offer.shortDescription}
              title={offer.title}
              whatsIncludedTitleDictKey={offer.whatsIncludedTitleDictKey}
              disabled={selectedPackage && offer.resultId !== selectedPackage}
            />
          </li>
        ))}
      </ul>
    </>
  );
}

export default AvailablePackages;
