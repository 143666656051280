import {
  format,
  min,
  parse,
  startOfYear,
  sub,
  isBefore,
  isValid,
} from "date-fns";
import get from "lodash/fp/get";
import pick from "lodash/fp/pick";
import head from "lodash/fp/head";
import compose from "lodash/fp/compose";

import * as dateFnLocales from "date-fns/locale";
import { getDateFnsLocale } from "config/languages";

const maxEmployeeStay = 8;
const limitRoomNightDays = 30;

export const getInfoToRetrieveSoonestBooking = compose(
  pick(["reservationId", "hotelCode"]),
  get(["hotelProducts", "0"]),
  head
);

export const formatToTwelveHours = (date, locale) =>
  format(date, "h:mma", {
    locale: dateFnLocales[getDateFnsLocale(locale)],
  }).toLowerCase();

export const parseDateWithTime = (date) =>
  parse(date, "yyyy-MM-dd HH:mm", new Date());

export const getEmployeeServiceDateThisYear = (employee) => {
  if (employee?.serviceDate?.length === 10) {
    const dateFromServiceDate = parse(
      `${format(new Date(), "yyyy")}${employee.serviceDate.substring(4, 10)}`,
      "yyyy-MM-dd",
      new Date()
    );
    if (isValid(dateFromServiceDate)) {
      return dateFromServiceDate;
    }
  }
  return undefined;
};

export const getDateForEmployeeStayHistory = (stayHistoryMonths, employee) => {
  let dateFromServiceDate = getEmployeeServiceDateThisYear(employee);
  if (dateFromServiceDate) {
    if (isBefore(sub(new Date(), { days: 1 }), dateFromServiceDate)) {
      dateFromServiceDate = sub(dateFromServiceDate, { years: 1 });
    }
    return format(dateFromServiceDate, "yyyy-MM-dd");
  }
  return format(sub(new Date(), { months: stayHistoryMonths }), "yyyy-MM-dd");
};

export const getDateForEmployeeRoomNights = (
  {
    checkIn = format(new Date(), "yyyy-MM-dd"),
    checkOut = format(new Date(), "yyyy-MM-dd"),
  } = {},
  employeeProfile
) => {
  let arriveStartFromService;
  const dateFromServiceDate = getEmployeeServiceDateThisYear(employeeProfile);
  if (dateFromServiceDate) {
    arriveStartFromService = dateFromServiceDate;
    if (isBefore(new Date(), dateFromServiceDate)) {
      arriveStartFromService = sub(dateFromServiceDate, { years: 1 });
    }
  }

  return format(
    sub(
      min([
        ...(arriveStartFromService ? [arriveStartFromService] : []),
        startOfYear(parse(checkIn, "yyyy-MM-dd", new Date())),
        sub(parse(checkOut, "yyyy-MM-dd", new Date()), {
          days: limitRoomNightDays,
        }),
      ]),
      { days: maxEmployeeStay - 1 }
    ),
    "yyyy-MM-dd"
  );
};
